import axios from 'axios';
import {getRequest} from "@/api/index";

export const getCategories = async () => {
    const shop_id = localStorage.getItem('shop_id')
    try {
        return await getRequest(`/game/categories?shop_id=${shop_id}`)
    } catch (error) {
        console.error('Ошибка при получении категорий:', error)
    }
}

export const getGamesCategory = async (category, device) => {
    const shop_id = localStorage.getItem('shop_id')

    try {
        return await getRequest(`/game/games?category=${category}&device=${device}&shop_id=${shop_id}`)
    } catch (error) {
        console.error('Ошибка при получении игр категории:', error)
    }
}

export const getAllGames = async () => {
    const shop_id = localStorage.getItem('shop_id')

    try {
        return await getRequest(`/game/allgames?shop_id=${shop_id}`)
    } catch (error) {
        console.error('Ошибка при получении всех игр:', error)
    }
}