import {defineStore} from "pinia";
import {ref} from "vue";
import CryptoJS from "crypto-js";
import router from "@/router/router";
import {login} from "@/api/auth";

export const useAuthStore = defineStore('auth', () => {
    const username = ref('')
    const password = ref('')

    const handlerLogin = async () => {
        const hashedPassword = CryptoJS.SHA256(password).toString();

        const isLogged = await login(username.value, hashedPassword)

        if (isLogged) {
            localStorage.setItem('username', isLogged.Username)
            localStorage.setItem('shop_id', isLogged.Shop_id)

            await router.push('/')
        } else {
            localStorage.clear();
        }
    }

    const logout = async () => {
        localStorage.clear()
        await router.push('/login')
    }

    return {
        username,
        password,
        handlerLogin,
        logout
    }
})
