<script setup>

</script>

<template>
  <div class="wrapper">
    <router-view/>
  </div>
</template>

<style scoped>
.wrapper {
  width: 100%;
  height: 100vh;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;
  background-color: #f0f0f0;
}
</style>
