import { createRouter, createWebHistory } from 'vue-router';
import LoginView from "@/views/LoginView.vue";
import HomeView from "@/views/HomeView.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/:pathMatch(.*)',
        redirect: { name: 'home' } // Перенаправляем на главную страницу
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const isLoggedIn = localStorage.getItem('username'); // Проверяем, есть ли данные о логине в локальном хранилище
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // Проверяем, залогинен ли пользователь
        if (!isLoggedIn) {
            // Пользователь не залогинен, перенаправляем на страницу логина
            next({ name: 'login' });
        } else {
            next();
        }
    } else {
        // Если пользователь авторизован и пытается зайти на страницу логина, перенаправляем его на главную страницу
        if (isLoggedIn && to.name === 'login') {
            next({ name: 'home' });
        } else {
            next();
        }
    }
});

export default router;