import axios from 'axios';

const URL = process.env.VUE_APP_BASE_URL;

export const handleRequestError = (error) => {
    console.error('Request Error:', error);
    throw new Error(error);
};

export const getRequest = async (endpoint) => {

    try {
        const response = await axios.get(URL + endpoint);

        return response.data;
    } catch (error) {
        handleRequestError(error)
        return null;
    }
};

export const postRequest = async (endpoint, data) => {
    try {
        const response = await axios.post(URL + endpoint, data);

        return response.data;
    } catch (error) {
        handleRequestError(error)
        return null;
    }
};