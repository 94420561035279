import {defineStore} from "pinia";
import {ref} from "vue";
import {getUser} from "@/api/others";

export const useUtilsStore = defineStore('utils', () => {
    const lang = ref(localStorage.getItem('lang') || 'en')
    const langList = ['en', 'de']
    const balance = ref('0.00')
    const username = ref('')

    const changeLang = (val) => {
        lang.value = val
        localStorage.setItem('lang', val)
    }

    const getUserData = async () => {
        const user = await getUser(localStorage.getItem('username'))

        balance.value = user.Balance.toFixed(2)
        username.value = user.Username
    }

    return {
        getUserData,
        changeLang,
        lang,
        langList,
        username,
        balance
    }
})
