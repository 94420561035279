import {defineStore} from "pinia";
import {ref} from "vue";
import {getAllGames, getGamesCategory} from "@/api/games";

export const useGamesStore = defineStore('games', () => {
    const games = ref([])
    const categories = ref([])
    const activeCategory = ref('')

    const getGames = async (name) => {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const device = isMobile ? 'mobile' : 'desktop'

        activeCategory.value = name
        games.value = await getGamesCategory(name, device)
    }

    const getAll = async () => {
        activeCategory.value = 'all'
        games.value = await getAllGames()
    }

    return {
        games,
        categories,
        activeCategory,
        getGames,
        getAll
    }
})