<script setup>
import GameList from "@/components/GameList.vue";
import {onBeforeMount, onUnmounted} from "vue";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import CategoriesGames from "@/components/CategoriesGames.vue";
import {useUtilsStore} from "@/store/utils";
import {useAuthStore} from "@/store/auth";

const utilsStore = useUtilsStore()
const authStore = useAuthStore()

let intervalId;

const updateUserData = () => {
  utilsStore.getUserData();
};

onBeforeMount(async () => {
  await utilsStore.getUserData();
  intervalId = setInterval(updateUserData, 10000);
});

onUnmounted(() => {
  clearInterval(intervalId);
});
</script>

<template>
  <div class="header">
    <CategoriesGames />
    <div class="username">
      {{ utilsStore.username }}
    </div>
    <div class="balance">
      {{ utilsStore.balance }} €
    </div>
    <LanguageSwitcher />
    <div class="logout" @click="authStore.logout()">
      Logout
    </div>
  </div>
  <div class="home">
    <GameList />
  </div>
</template>

<style scoped>
.home {
  margin-bottom: 20px;
}

.logout {
  padding: 10px;
  cursor: pointer;
  border: 1px solid #a0a0a0;
  transition: all .3s ease;
  color: #fff;
  font-weight: 700;
}

.logout:hover {
  color: #000;
  background-color: #f0f000;
}

.username {
  text-decoration: underline;
}

.username, .balance {
  color: #fff;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 40px;
  padding: 10px;
  background: #000;
}


@media (max-width: 770px) {
  .header {
    height: auto;
  }
}
</style>