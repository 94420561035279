<script setup>
import {useGamesStore} from "@/store/games";
import {onBeforeMount} from "vue";
import {getCategories} from "@/api/games";

const gamesStore = useGamesStore()

onBeforeMount(async () => {
  gamesStore.categories = await getCategories();
  console.log('Ответ сервера на все категории', gamesStore.categories)

  if (gamesStore.activeCategory === '') {
    await gamesStore.getAll()
  }
})
</script>

<template>
<div class="categories">
  <div
      class="item"
      :class="{'active': gamesStore.activeCategory === 'all'}"
      key="all"
      @click="gamesStore.getAll()"
  >All</div>
  <div
      class="item"
      :class="{'active': gamesStore.activeCategory === category.CategorySrc}"
      v-for="category in gamesStore.categories"
      :key="category.CategoryTitle"
      @click="gamesStore.getGames(category.CategorySrc)"
  >{{ category.CategoryTitle }}</div>
</div>
</template>

<style scoped>
.categories {
  display: flex;
  align-items: center;
  gap: 15px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}

.item {
  padding: 10px;
  cursor: pointer;
  border: 1px solid #a0a0a0;
  transition: all .3s ease;
  color: #fff;
}

.item.active {
  color: #000;
  background-color: #f0f000;
}

.item:hover {
  color: #000;
  background-color: #f0f000;
}

@media (max-width: 770px) {
  .categories {
    gap: 10px;
    padding: 10px;
    flex-wrap: wrap;
    font-size: 14px;
  }
}
</style>