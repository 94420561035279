<script setup>

import {ref} from "vue";
import {useUtilsStore} from "@/store/utils";

const utilsStore = useUtilsStore()
const showMenu = ref(false)

window.onclick = (event) => {
  if (!event.target.matches('.selected_locale')) {
    showMenu.value = false
  }
}

// const la = process.env.BASE_URL
</script>

<template>
  <!-- кнопка с флагом текущей локали. При клике - открывается блок ниже (как выпадающее меню) а так же подложка, чтобы при клике на нее меню закрыть -->
  <div class="lang_switch">
    <div class="selected_locale" @click="showMenu = !showMenu">
      {{utilsStore.lang}}
    </div>
    <div class="lang_menu" v-if="showMenu">
      <div class="locale_wrapper" v-for="sLocale in utilsStore.langList" :key="sLocale" @click="utilsStore.changeLang(sLocale)">
        <div class="locale_name">{{ sLocale }}</div>
      </div>
    </div>

  </div>
</template>

<style scoped>
.locale_name{
  margin-bottom: 10px;
  align-self: center;
  z-index: 2;
  text-transform: uppercase;
}
.locale_wrapper{
  display: flex;
  cursor: pointer;
  z-index: 2;
}
.locale_flag img{
  width: 30px;
  border-radius: 2px;
  margin: 5px;
}
.lang_menu{
  position: absolute;
  width: 100%;
  height: 50px;
  background: #000000;
  box-shadow: 4px 4px 5px #6a6a6a;
  color: #fff;
  margin-top: 5px;
  border-radius: 5px;
  z-index: 2;
}
.lang_switch{
  position: relative;
  align-self: center;
  margin: 0 5px;
  margin-right: 20px;
  min-width: 25px;
  z-index: 2;
}
.selected_locale{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  border-radius: 2px;
  cursor: pointer;
  z-index: 2;
  text-transform: uppercase;
  font-weight: 700;
  color: #fff;
  text-decoration: underline;
  padding: 5px;
  border: 1px solid #fff;
}
</style>