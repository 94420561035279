<script setup>
import defineProps from 'vue'
import {getGameUrl} from "@/api/others";
const props = defineProps({
  data: Object,
  category: String,
  changeUrl: Function
})

const URL = process.env.VUE_APP_BASE_API;

const openGame = async () => {
  const lang = localStorage.getItem('lang') || 'EN'
  const url = URL + await getGameUrl(props.data.game_category, props.data.game_name) + `?currency=EUR&lang=${lang}`
  props.changeUrl(url)

  addEventListener('message', function (ev) {
    console.log('message', ev)
    if (ev.data === 'CloseGame' || ev.data === 'STATE:Closing game...' || ev.data.name === 'gameQuit') {
      props.changeUrl('')
    }
  });
}
</script>

<template>
<div class="game" @click="openGame">
  <div class="game_wrapper">
    <img :src="`https://funbetwin.com/frontend/Vegas/ico/${props.data.game_category}/${props.data.game_name}.jpg`" alt="">
  </div>
  <div class="title">
    {{ data.game_title }}
  </div>
</div>
</template>

<style scoped>
.game {
  width: 150px;
  cursor: pointer;
  border-radius: 5px;
}

.game_wrapper {
  width: 150px;
  height: 150px; /* Фиксированная высота */
  overflow: hidden; /* Скрыть содержимое, выходящее за пределы контейнера */
}

img {
  width: 150px;
  height: 150px; /* Фиксированные размеры для изображения */
  overflow: hidden;
  transition: all .3s ease;
}

.title {
  padding: 5px;
  text-align: center;
  background-color: #ddd;
}

.game:hover img {
  transform: scale(1.1);
}

.game:hover .title {
  background: #ccc;
}
</style>