import axios from 'axios';
import {postRequest} from "@/api/index";

export const login = async (username, password) => {
    try {
        return await postRequest(`/auth/login`, {
            username: username,
            password: password
        });
    } catch (error) {
        console.error(error);
    }
}