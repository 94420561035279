import {getRequest} from "@/api/index";


export const getGameUrl = async (category, game) => {
    const shop_id = localStorage.getItem('shop_id')
    const username = localStorage.getItem('username')
    try {
        return await getRequest(`/game/gameUrl?shop_id=${shop_id}&category=${category}&game=${game}&username=${username}`);
    } catch (error) {
        console.error(error);
    }
}

export const getUser = async () => {
    const username = localStorage.getItem('username')
    try {
        return await getRequest(`/getUserData/${username}`);
    } catch (error) {
        console.error(error);
    }
}