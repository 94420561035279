<script setup>
import {useAuthStore} from "@/store/auth";

const authStore = useAuthStore()
</script>

<template>
  <div class="form">
    <h2 class="title">Login</h2>
    <input v-model="authStore.username" type="text" placeholder="Username">
    <input v-model="authStore.password" type="password" placeholder="Password">
    <button @click="authStore.handlerLogin">Login</button>
  </div>
</template>

<style scoped>
.form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid silver;
  box-sizing: border-box;
}

.form input {
  width: 90%;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

.form button {
  padding: 5px 10px;
  text-transform: uppercase;
  font-weight: 500;
}

.title {
  width: 100%;
  text-align: center;
  font-size: 24px;
}
</style>