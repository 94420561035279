<script setup>
import {useGamesStore} from "@/store/games";
import GameItem from "@/components/GameItem.vue";
import {ref} from "vue";

const gamesStore = useGamesStore()

const url = ref('')
const changeUrl = (val) => url.value = val
</script>

<template>
  <div class="games_list">
    <GameItem v-for="game in gamesStore.games"
      :key="game.name"
      :data="game"
      :category="gamesStore.activeCategory"
      :changeUrl="changeUrl"
    />
  </div>

  <div class="cross" v-if="url !== ''" @click="changeUrl('')"></div>

  <iframe
      v-if="url !== ''"
      :src="url"
      style="border: 0;
      position:fixed; top:0; left:0; right:0; bottom:0; width:100%; height:100%; z-index: 10;"
      allowfullscreen>
  </iframe>
</template>

<style scoped>
.games_list {
  display: flex;
  align-items: start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 10px;
}

.cross {
  position: absolute;
  right: 10px;
  top: 0;
  width: 50px;
  height: 50px;
  background-color: lightgray;
  z-index: 99;
  cursor: pointer;
  transition: all .3s ease;
}

.cross:hover {
  background-color: #717171;
}

.cross::before,
.cross::after {
  content: '';
  position: absolute;
  width: 70%;
  height: 2px;
  background-color: black;
  top: 50%;
  left: 15%;
  transform: translate(-50%, -50%);
}

.cross::before {
  transform: rotate(45deg);
}

.cross::after {
  transform: rotate(-45deg);
}

</style>